<script setup lang="ts">
import type { PromoCard } from '~/types/storyblok/promo-card'
import type { EdgeStyles } from '~/types/storyblok/shared'
import type { trackBlockClickEmit } from '~/types/track-block-click'
import { getEdgeClasses } from '~/lib/getEdgeClasses'

const props = defineProps<{
  blok: PromoCard
  edgeStyle: EdgeStyles
  index: number
  forceGridMobile: boolean
  noText: boolean
}>()
const emit = defineEmits<trackBlockClickEmit>()

const edgeClass = computed(() => getEdgeClasses(props.edgeStyle))

function calculatePosition(index: number, noText: boolean) {
  let order = index
  if (order == 1)
    order = 2
  else if (order == 2)
    order = 1
  const row = order % 2 + 1
  if (!noText)
    order = order * 2

  return { order, row }
}

const { order, row } = calculatePosition(props.index, props.noText)
const elementType = computed(() => props.blok.link.cached_url ? resolveComponent('NuxtLink') : 'div')
</script>

<template>
  <PromoCardText
    v-if="!noText && blok.textContentAboveTheImage"
    :blok="blok"
    :no-text="noText"
    :element-type="elementType"
    :class="[edgeClass, { order: forceGridMobile }]"
    :style="{ '--order': order }"
  />
  <component
    :is="elementType"
    v-editable="blok"
    :to="$url(blok.link.cached_url)"
    class="image-link"
    :class="[{ order: forceGridMobile }]"
    :style="{ '--order': blok.textContentAboveTheImage ? order + 1 : order, '--row': row }"
    @click="() => emit('trackBlockClick', $url(blok.link.cached_url), 'link')"
  >
    <ImageResponsive
      :class="[edgeClass]"
      provider="storyblok"
      :src="blok.image.filename"
      :alt="blok.imageAlt"
      height="100%"
      object-fit="cover"
    />
  </component>
  <PromoCardText
    v-if="!noText && !blok.textContentAboveTheImage"
    :blok="blok"
    :no-text="noText"
    :element-type="elementType"
    :class="[edgeClass, { order: forceGridMobile }]"
    :style="{ '--order': order + 1 }"
  />
</template>

<style lang="scss" scoped>
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/edges';
@import 'assets/scss/rules/breakpoints';

.order {
  order: var(--order);
  @media screen and (min-width: $tablet) {
     order: unset;
  }
}

.teeth {
  @include teeth;
  margin-top: -1.2rem;
}

.scallop {
  @include scallop;
  margin-top: -1.6rem;
}

.wave {
  @include wave;
  margin-top: -1.2rem;
}

.image-link {
  border: 2px solid transparent;
    &:focus-visible {
      border: 2px solid var(--black);
      border-radius: 2px;
      outline:none;
    }
  }
</style>
